<template>
  <div class="processManage">
    <div>
      <!-- 应用加固 -->
      <navi-gation />
      <div class="one" v-show="isHidden == 1">
        <el-row :gutter="4" class="mt15" style="display: flex">
          <el-col :span="3">
            <el-input v-model.trim="queryObj.name" size="small" :placeholder="$t('AppManage.Appdetection.PleaseApp')" @keyup.enter.native="searchList"></el-input>
          </el-col>
          <el-col :span="4">
            <el-button size="small" type="primary" @click="searchList">{{$t("public.Inquire")}}</el-button>
          </el-col>
        </el-row>
        <el-row style="padding: 0px 10px 10px 10px" :gutter="4">
          <el-col :span="24">
            <el-button-group style="display: flex">
              <!-- <el-upload class="upload-demo1" ref="upload" accept=".apk,.ipa" :file-list="fileList" :action="'/sysFile/fileUp'" :on-change="uploadChange" :auto-upload="false">
                <el-button class="upBtn" slot="trigger" size="small" type="primary">
                  上传应用
                </el-button>
              </el-upload> -->
              <el-button @click="isHidden = 2" type="primary" size="small">{{$t("AppManage.Appdetection.UploadAndroidApp")}}</el-button>
              <el-button @click="isHidden = 3" type="primary" size="small">{{$t("AppManage.Appdetection.UploadiOSApp")}}</el-button>
              <el-button @click="clickStrong" :disabled="btnJiagu" :type="typeInfo5" size="small">{{$t("AppManage.Appreinforce.Securityharden")}}</el-button>
              <el-button :disabled="hasSeleted || deletesBtn" :type="typeInfo2" size="small" @click="deletes" style="margin-left: .6px">{{$t('public.Delete')}}</el-button>
            </el-button-group>
          </el-col>
        </el-row>
        <el-row class="formBox" :gutter="16" style="padding: 0 20px">
          <div style="width: 100%; position: relative">
            <el-link type="primary" style="z-index: 1; position: absolute; top: -20px; right: 90px" @click="downFile('public/tools/iOS_OBF.zip')">
              <img style="width: 15px; color: blue" src="@/images/down1.png" alt="" />
              {{$t("AppManage.Appreinforce.DownloadiOS")}} /
            </el-link>
            <el-link type="primary" style="z-index: 1; position: absolute; top: -20px; right: 0" @click="downFile('public/tools/SignTool.zip')">
              {{$t("AppManage.Appreinforce.Downloadsignature")}}
            </el-link>
          </div>
          <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" stripe ref="multipleTable" :data="dictData" @selection-change="handleSelectionChange" @sort-change="sort" :default-sort="{ prop: 'createtime', order: 'descending' }" style="width: 100%" size="mini" class="tableClass">
            <el-table-column type="selection" align="center" width="60" >
            </el-table-column>
            <el-table-column prop="appName" :label="$t('public.ApplicationName')" width="160" sortable="custom" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <el-link style="color: #d38a08" @click="nameDetail(row)" type="warning" :underline="false">
                  <span style="color: #d38a08;font-size: 12px;">{{ row.appName }}</span>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="versionName" :label="$t('public.Version')" width="80" sortable="custom" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="appSizeStr" :label="$t('public.Size')" width="80" sortable="custom" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('AppManage.Appreinforce.Plusstate')" width="120" sortable="custom" prop="purgeStatus" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <template v-if="row.purgeStatus == 0">
                  <span>未沙箱未加固</span>
                </template>
                <template v-if="row.purgeStatus == 1">
                  <span>未沙箱已加固</span>
                </template>
                <template v-if="row.purgeStatus == 4">
                  <span>未沙箱已加固(第三方)</span>
                </template>
                <template v-if="row.purgeStatus == 5">
                  <span>未沙箱已加固(上讯)</span>
                </template>
                <template v-if="row.purgeStatus == 6">
                  <span>已沙箱已加固</span>
                </template>
                <template v-if="row.purgeStatus == 7">
                   <span>已沙箱未加固</span>
                </template>
                <template v-if="row.purgeStatus == 8">
                   <span>已沙箱已加固(上讯)</span>
                </template>
                <template v-if="row.purgeStatus == 9">
                  <span>已沙箱(上讯)未加固</span>
                </template>
                <template v-if="row.purgeStatus == 10">
                   <span>已沙箱(上讯)已加固</span>
                </template>
                <template v-if="row.purgeStatus == 3">
                   <!-- 加固失败 -->
                    <span>{{
                      $t("AppManage.AppManagePage.page.ReinforcementFailure")
                    }}</span>
                </template>
                <template v-if="row.purgeStatus == 2">
                   <!-- 加固中 -->
                    <span>{{
                      $t("AppManage.AppManagePage.page.Strengthening")
                    }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column :label="$t('AppManage.Appreinforce.Apptactics')" width="120" sortable="custom" prop="appPermissionName" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <template v-if="row.appPermissionName == null">/</template>
                <template v-else> {{row.appPermissionName}} </template>
              </template>
            </el-table-column>
            <el-table-column prop="osTypeId" :label="$t('public.OperatingSystem')" width="110" sortable="custom" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <template v-if="row.osTypeId == 20"> Android </template>
                <template v-else> iOS </template>
              </template>
            </el-table-column>

            <el-table-column prop="groupFullName" :label="$t('public.Section')" width="90" sortable="custom" :show-overflow-tooltip="true">
              <template v-slot="{ row }">
                <span v-if="row.groupFullName">
                  {{ row.groupFullName.substr(groupFullNameShowByIndex)}}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="createtime" :label="$t('AppManage.Appreinforce.uploaded')" width="180" sortable="custom" :show-overflow-tooltip="true">
              <template v-slot="{ row }">{{ row.createtime | dateformat }}</template>
            </el-table-column>
            <el-table-column :label="$t('public.Controls')" header-align="left" align="left">
              <template v-slot="{ row }">
                <span class="action_icon" v-if="row.purgeStatus ==0" @click="distribution(row)"> 
                  <img src="@/images/icon_fasten.png" :title="$t('AppManage.Appreinforce.Securityharden')" alt="" style=" width: 15px;height: 15px;cursor: pointer; " />
                </span>
                <span class="action_icon" v-if="row.purgeStatus ==1" @click="distribution(row)"> 
                  <img src="@/images/icon_fasten.png" :title="$t('AppManage.Appreinforce.Securityharden')" alt="" style=" width: 15px;height: 15px;cursor: pointer; " />
                </span>
                <span class="action_icon" v-if="row.purgeStatus ==6" @click="distribution(row)"> 
                  <img src="@/images/icon_fasten.png" :title="$t('AppManage.Appreinforce.Securityharden')" alt="" style=" width: 15px;height: 15px;cursor: pointer; " />
                </span>
                <span class="action_icon" v-if="row.purgeStatus ==7" @click="distribution(row)"> 
                  <img src="@/images/icon_fasten.png" :title="$t('AppManage.Appreinforce.Securityharden')" alt="" style=" width: 15px;height: 15px;cursor: pointer; " />
                </span>
                <span class="action_icon" v-if="row.purgeStatus ==9" @click="distribution(row)"> 
                  <img src="@/images/icon_fasten.png" :title="$t('AppManage.Appreinforce.Securityharden')" alt="" style=" width: 15px;height: 15px;cursor: pointer; " />
                </span>
                <span class="action_icon" v-if="row.purgeStatus ==10" @click="distribution(row)"> 
                  <img src="@/images/icon_fasten.png" :title="$t('AppManage.Appreinforce.Securityharden')" alt="" style=" width: 15px;height: 15px;cursor: pointer; " />
                </span>
                <span class="action_icon" v-if="row.purgeStatus ==3" @click="distribution(row)"> 
                  <img src="@/images/icon_fasten.png" :title="$t('AppManage.Appreinforce.Securityharden')" alt="" style=" width: 15px;height: 15px;cursor: pointer; " />
                </span>
                <span class="action_icon" @click="download(row)">
                  <img src="@/images/icon_download.png" :title="$t('public.Download')" alt="" style=" width: 15px; height: 15px; cursor: pointer; " />
                </span>
                <span class="action_icon" @click="deletes(row)">
                  <img src="@/images/icon_del.png" alt="" :title="$t('public.Delete')" style=" width: 15px; height: 15px; cursor: pointer; " />
                </span>
                <!-- v-if="row.appStatus !== 2" -->

              </template>
            </el-table-column>
          </el-table>
          <pagination :page="queryObj.pageNumber" :limit="queryObj.rowNumber" :total="total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
        </el-row>
      </div>
      <shopAEditor v-if="isHidden == 2" @haldesotr="haldesotr" :androidData="androidData" />
      <shopIEditor v-if="isHidden == 3" @haldesotr="haldesotr" :androidData="androidData" />
      <shoptest v-if="isHidden == 4" :dataArray="dataArray" :scanScore="dataArray.scanScore" :sidd="dataArray.id" @haldesotr="haldesotr3"></shoptest>
      <ios-test v-if="isHidden == 6" :dataArray="dataArray" :scanScore="dataArray.scanScore" :sidd="dataArray.id" @haldesotr="haldesotr3"></ios-test>
      <!-- <shoptest v-if="isHidden == 4" :dataArray="dataArray" :scanScore="dataArray.scanScore" :sidd="dataArray.id" @haldesotr="haldesotr3"></shoptest>
      <ios-test v-if="isHidden == 6" :dataArray="dataArray" :scanScore="dataArray.scanScore" :sidd="dataArray.id" @haldesotr="haldesotr3"></ios-test> -->
      <shopstrong v-if="isHidden == 5" @haldesotr="haldesotr1" :sidd="dataArray.id" :androidData="dataArray"></shopstrong>
    </div>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
    <!-- 名称详情 -->
    <Eldialog @handleClose="handleClose" :title="title" :dialogVisible="dialogVisible" @heightCustom="heightCustom" @determine="determine" :flagbtn="false">
      <namedetail @aaa="handleClose" :dictData="dataFrom" />
    </Eldialog>
    <!-- 加固详情 -->
    <Eldialog class="dialog" @handleClose="handleClose" :title="$t('AppManage.Appreinforce.Reinforcement')" :dialogVisible="jiaGuVisible" @heightCustom="heightCustom">
      <el-row style="width: 100%" :gutter="16">
        <el-col style="padding: 0 40px" v-for="(item, index) in jiaGlist" :key="index" class="clo24" :span="24">
          <div v-if="item.name == $t('AppManage.Appreinforce.SOfile')">
            <el-checkbox v-model="checksign_type">{{ item.name }}</el-checkbox>
            <p style="padding: 10px 30px; color: #000" v-for="(item, i) in item.children" :key="i">
              {{ item }}
            </p>
          </div>
          <div v-else>
            <el-checkbox v-model="checksign_type">{{ item.name }}</el-checkbox>
            <p style="padding: 10px 30px; color: #000" v-if="item.children">
              {{ item.children }}
            </p>
          </div>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 下载弹框 -->
    <Eldialog @handleClose="handleClose" :title="$t('AppManage.Appreinforce.DownloadApps')" :dialogVisible="downFlag">
      <el-row style="width: 100%" :gutter="8">
        <el-col class="col12" :span="12">
          <el-button @click="downxiazai" type="primary">{{ $t('AppManage.Appreinforce.OriginalApplication') }}</el-button>
        </el-col>
        <el-col class="col12" :span="12">
          <el-button :disabled="!statusDown" @click="downxiazai1" type="primary">{{ $t('AppManage.Appreinforce.ReinforcementApplication') }}</el-button>
        </el-col>
      </el-row>
    </Eldialog>
  </div>
</template>
<script>
import Eldialog from '@/components/elDialog'
// 详情
import namedetail from './components/safetyAppDetail.vue'
// ios 编辑
import shopIEditor from './copentUps/shopIEditor.vue'
// Android  编辑
import shopAEditor from './copentUps/shopAEditor.vue'
// 应用检测
import shoptest from './components/shoptest.vue'
// 应用加固
import shopstrong from './components/shopstrong.vue'
import dialogInfo from '@/components/promptMessage/index'
import naviGation from '@/components/hearder/index'
import pagination from '@/components/pagination/page.vue'
import IosTest from './components/iosTest.vue'
export default {
  components: {
    pagination,
    naviGation,
    dialogInfo,
    Eldialog,
    namedetail, //名称详情弹框
    shopAEditor, //Android编辑
    shopIEditor, //IOS编辑
    shopstrong, //应用加固
    shoptest,
    IosTest //应用检测
  },
  data() {
    return {
      ReinforcedText:infoConfig.ReinforcedText,
      groupFullNameShowByIndex: '',
      pathImg: '',
      activeName: 'first',
      installFlag: false,
      tableAn: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1517 弄'
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1519 弄'
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1516 弄'
        }
      ],
      tableAn1: [],
      checksign_type: true,
      path: '',
      downFlag: false,
      checked: true,
      isHidden: 1, // 显示当前 / 编辑页面
      androidData: {}, //子组件--Android编辑
      iosData: {}, //子组件--ios编辑
      typeInfo: 'info',
      typeInfo1: 'info',
      typeInfo2: 'info',
      typeInfo3: 'info',
      typeInfo4: 'info',
      typeInfo5: 'info',
      btnJiagu: true,
      rulesHTML: 'dasdasd',
      num: 220,
      jiaGuVisible: false,
      flagbtn: false, //footer
      options1: [
        {
          value: '',
          label: '安装类型'
        },
        {
          value: '1',
          label: '普通应用'
        },
        {
          value: '2',
          label: '必装应用'
        }
      ],
      options2: [
        {
          value: '',
          label: '防护类型'
        },
        {
          value: '1',
          label: '未做防护'
        },
        {
          value: '0',
          label: '安全沙箱'
        },
        {
          value: '3',
          label: '安全加固'
        },
        {
          value: '2',
          label: '加固沙箱'
        }
      ],
      options3: [
        {
          value: '',
          label: '应用状态'
        },
        {
          value: '1',
          label: '未上架'
        },
        {
          value: '2',
          label: '已上架'
        },
        {
          value: '3',
          label: '已下架'
        }
      ],
      // 提示框的提示语
      titleInfo: '',
      // applyFlag: false, //选择应用
      // statusVisible: false, //状态弹框
      title: '应用详情', //应用详情title
      title1: '应用编辑',
      visibaelFlag: false, //提示消息默认--关闭
      dialogVisible: false, //详情弹框默认--关闭
      dataFrom: {}, //应用详情组件
      // table表格数据
      dictData: [],
      multipleSelection: [],
      queryObj: {
        pageNumber: 1,
        rowNumber: 10,
        manage: 1,
        appType: '',
        preventScreenshot: '',
        statu: '',
        name: '',
        sort: '',
        orderColume: 'createtime',
        orderRule: 'DESC'
      },
      total: 0,
      // 遮罩全选
      btnJiance: true,
      isCheckShow: false,
      hasSeleted: true,
      theShelves: true,
      shelves: true,
      deletesBtn: true,
      dataArray: {},
      sidd: '',
      jiaGlist: [],
      statusDown: false,
      // 上传
      up: {
        type: 'protected',
        files: ['']
      },
      downloadVisible: false, //下载文件弹框
      //上传文件参数
      fileList: [],
      //上传文件保存接口参数
      uploadFileParams: {
        // fileName: "",
        // size: "",
        // path: "", //（地址）
        appName: ''
      },
      form: {
        fileList: []
      },
      //传参数
      filePath: '' //文件存储地址
    }
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
    this.tableList() //应用商店table数据表格
  },
  methods: {
    // 上传
    async uploadChange(file) {
      let fileName = file.name
      let suffix = file.name?.substring(fileName.lastIndexOf('.') + 1) //获取文件后缀名
      let suffixArray = ['apk', 'ipa']
      // console.log(suffix, "1111");
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t('AppManage.Appdetection.Fileformaterror'),
          type: 'error',
          duration: 2000
        })
      } else {
        const form = new FormData()
        // 文件对象
        form.append('files', file.raw)
        form.append('type', this.up.type)
        form.append('fileType', suffix)
        form.append('moduleName', 'protected')
        const res = await this.$axios.upload(
          '/httpServe/sysFile/fileUp',
          form,
          this.updateProgress,
          true
        )
        this.uploadFileParams.appName = file.name
        // this.uploadFileParams.size = file.size;

        // console.log(res);
        // console.log(this.uploadFileParams);
        if (res.data.code == '200') {
          let path = res.data.data[0]
          this.save(path, fileName)
        } else {
          // this.visibaelFlag = true
          // this.titleInfo = '上传文件成功'
          this.$message({
            message: this.$t('AppManage.Appdetection.UploadSuccess'),
            type: 'success',
            offset: 100
          })
          this.time = setInterval(() => {
            this.Sencond -= 1
          }, 1000)
          this.tableList()
        }
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
    },
    async save(path, fileName) {
      var params = {
        appName: fileName,
        path: path
      }

      const res = await this.$axios.post(
        '/httpServe/purgeApp/save',
        params,
        true
      )
      if (res.data == 1) {
        // this.visibaelFlag = true
        // this.titleInfo = '应用上传成功'
        this.$message({
          message: this.$t('AppManage.Appdetection.Appsuccess'),
          type: 'success',
          offset: 100
        })
        this.time = setInterval(() => {
          this.Sencond -= 1
        }, 1000)
        this.tableList()
      } else {
        // this.visibaelFlag = true
        // this.titleInfo = '应用上传失败'
        this.$message({
          message: this.$t('AppManage.Appdetection.Apperror'),
          type: 'error',
          offset: 100
        })
        this.time = setInterval(() => {
          this.Sencond -= 1
        }, 1000)
        this.tableList()
      }
    },
    testShop() {
      // console.log(this.dataArray);
      if (this.dataArray.osTypeId == 20) {
        this.isHidden = 4
      } else {
        this.isHidden = 6
      }
    },
    selectEnable(row, rowIndex) {
      // //  0 ||row.purgeStatus == 1|| row.purgeStatus == 6||row.purgeStatus == 7||row.purgeStatus == 9||row.purgeStatus == 10||row.purgeStatus == 3
      // if (row.purgeStatus !==2) {
      //   return true
      // } else {
      //   return false
      // }
    },
    async azFenPei(row) {
      this.activeName = 'first'
      let params = {
        entAppId: row.id,
        deviceName: ''
      }
      this.titleInfo = row.appName + this.$t('AppManage.Appdetection.Instacondi')
      const res = await this.$axios.post(
        '/api/entApp/installDevice',
        params,
        true
      )
      // console.log(res);
      if (res.data) {
        this.tableAn1 = res.data.assign
        this.tableAn = res.data.install
      }
      this.installFlag = true
    },
    async downFile(url) {
      var param = {
        path: url
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      // console.log(res);
      await this.$axios.downloadFile(res.data)
    },
    haldesotr() {
      this.$nextTick(() => {
        this.isHidden = 1
      })
      this.$message({
        message: this.$t('public.SuccessfullySaved'),
        type: 'success',
        offset: 100
      })
      this.searchList()
    },
    haldesotr1() {
      this.isHidden = 1
      this.searchList()
    },
    haldesotr3() {
      this.searchList()
      this.titleInfo = this.$t('AppManage.Appdetection.Betesting'),
      this.visibaelFlag = true
      this.isHidden = 1
    },
    // 分配
    jump2Detail: function (menu, param) {
      this.$axios.setOneTimeParam('menu', menu)
      this.$parent.$parent.$parent.$parent.$parent.clickMenu()
      if (param) {
        this.$axios.postMessage(param)
      }
    }, // 分配
    async distribution(row) {
      this.dataArray = row
      // this.dataArray.id = row.id;
      console.log(this.dataArray.id, '操作详情')
      let param = {
        ids: [row.id], //应用id
        appName: row.appName, //应用名称
        osType: row.osTypeId //安卓-20 ios-10
      }
      console.log(param)
      const res = await this.$axios.post(
        '/httpServe/entApp/getPurgeInfo',
        param,
        true
      )
      console.log(JSON.parse(res.data))
      if (res.data) {
        this.dataArray.purgeInfo = res.data
        this.isHidden = 5
      } else {
        this.isHidden = 5
      }
      // this.dataArray = row
      // console.log(this.dataArray);
      // this.dataArray.id = row.id
      // console.log(this.dataArray.id);
      // this.isHidden = 5
    },
    distribution1() {
      let data = []
      this.multipleSelection.forEach((item, index) => {
        data.push(item.id)
      })
      this.jump2Detail('Delivery/Delivery', {
        param: {
          apps: data
        }
      })
    },
    sort(column) {
      if (column.prop == 'appName') {
        this.queryObj.orderColume = 'e.name'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'versionName') {
        this.queryObj.orderColume = 'e.versionName'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'appSizeStr') {
        this.queryObj.orderColume = 'e.appsize'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'purgeStatus') {
        this.queryObj.orderColume = 'ext.purge_status'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'appPermissionName') {
        // e.APPPERMISSIONID
        this.queryObj.orderColume = 'ep.name'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'osTypeId') {
        this.queryObj.orderColume = 'e.ostypeid'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'groupFullName') {
        this.queryObj.orderColume = 'g.groupFullName'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      if (column.prop == 'createtime') {
        this.queryObj.orderColume = 'e.createtime'
        if (column.order === 'ascending') {
          this.queryObj.orderRule = 'ASC'
        } else if (column.order === 'descending') {
          this.queryObj.orderRule = 'DESC'
        } else {
          this.queryObj.orderRule = ''
        }
      }
      this.tableList()
    },
    async downxiazai() {
      var param = {
        path: this.path
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      await this.$axios.downloadFile(res.data)
    },
    async downxiazai1() {
      // console.log(this.path)
      const strCopy = this.path.split('/')
      strCopy[strCopy.length - 1] = 'safe_' + strCopy[strCopy.length - 1]
      let data = strCopy.join('/')
      var param = {
        path: data
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      await this.$axios.downloadFile(res.data)
    },
    // 下载
    async download(val) {
      this.downFlag = true
      this.path = val.path
      this.statusDown = val.purgeStatus == 1||val.purgeStatus == 6 ||val.purgeStatus == 7 ||val.purgeStatus == 10 ? true : false;
    },
    jiaGuStatus(row) {
      this.jiaGlist = JSON.parse(row.purgeInfo).filter((item) => item.name)
      this.jiaGuVisible = true
    },
    // 上架
    shangjia() {
      this.$confirm('确定上架当前应用？', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.Sshelves()
        })
        .catch(() => {
          return false
        })
    },
    async Sshelves() {
      let data = []
      data.push(this.dataArray.id + '')
      let param = {
        appStatus: '2',
        id: data
      }
      // console.log(param);
      const res = await this.$axios.post(
        '/api/entApp/updateStatus',
        param,
        true
      )
      // this.titleInfo = '上架成功'
      // this.visibaelFlag = true
      this.$message({
        message: '上架成功',
        type: 'success',
        offset: 100
      })
      this.searchList()
    },
    // 下架
    xiajia() {
      this.$confirm('确定下架当前应用?', '提示信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.TheShelves()
        })
        .catch(() => {
          return false
        })
    },
    async clickStrong() {
      let param = {
        ids: [this.dataArray.id], //应用id
        appName: this.dataArray.appName, //应用名称
        osType: this.dataArray.osTypeId //安卓-20 ios-10
      }
      // console.log(param);
      const res = await this.$axios.post(
        '/httpServe/entApp/getPurgeInfo',
        param,
        true
      )
      // console.log(JSON.parse(res.data));
      if (res.data) {
        this.dataArray.purgeInfo = res.data
        this.isHidden = 5
      } else {
        this.isHidden = 5
      }
    },
    async TheShelves() {
      let data = []
      data.push(this.dataArray.id + '')
      let param = {
        appStatus: '3',
        id: data
      }
      // console.log(param);
      const res = await this.$axios.post(
        '/api/entApp/updateStatus',
        param,
        true
      )
      // this.titleInfo = '下架成功'
      // this.visibaelFlag = true
      this.$message({
        message: '下架成功',
        type: 'success',
        offset: 100
      })
      this.searchList()
    },
    //删除
    async deleteBaths(val) {
      if (val.id) {
        let data = []
        data.push(val.id)
        let param = {
          manage: 1,
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/entApp/delete',
          param,
          true
        )
        // this.titleInfo = '删除成功'
        // this.visibaelFlag = true
        this.$message({
          message: this.$t('public.SuccessfullyDeleted'),
          type: 'success',
          offset: 100
        })
        this.searchList()
      } else {
        // console.log(this.multipleSelection);
        let data = []
        this.multipleSelection.forEach((item, index) => {
          data.push(item.id)
        })
        let param = {
          manage: 1,
          ids: data
        }
        const res = await this.$axios.post(
          '/httpServe/entApp/delete',
          param,
          true
        )
        if (this.multipleSelection.length > 1) {
          // this.titleInfo = '批量删除成功'
          // this.visibaelFlag = true
          this.$message({
            message: this.$t('public.SuccessfullyDeleteds'),
            type: 'success',
            offset: 100
          })
          this.searchList()
        } else {
          this.$message({
            message: this.$t('public.SuccessfullyDeleted'),
            type: 'success',
            offset: 100
          })
          this.searchList()
        }
      }
    },
    // 名称详情
    nameDetail(val) {
      // console.log(val, "-----名称详情");
      this.dialogVisible = true
      this.dataFrom = { ...val }
      // console.log(this.dataFrom, "this.dataform");
    },
    // 渲染数据列表
    async tableList() {
      var param = {
        currentPage: this.queryObj.pageNumber,
        pageSize: this.queryObj.rowNumber,
        orderColume: this.queryObj.orderColume,
        orderRule: this.queryObj.orderRule,
        type: '1',
        appName: this.queryObj.name,
        isGetH5App:0,
        isGetParserLose:0,
        installType:'',
        isGetSafe:0,
      }
      console.log(param)
      // http95
      const res = await this.$axios.post(
        '/httpServe/entApp/getDataInfo',
        param,
        true
      )
      console.log(res, '应用商店table表格数据')
      this.dictData = res.data.content
      this.total = res.data.total
    },
    // 查询
    searchList() {
      this.queryObj.pageNumber = 1
      this.tableList()
    },
    handleSelectionChange(val) {
      this.multipleSelection = [...val]
      if (val.length == 0) {
        this.hasSeleted = true
        this.theShelves = true
        this.shelves = true
        this.deletesBtn = true
        this.btnJiance = true
        this.btnJiagu = true
        this.typeInfo = 'info'
        this.typeInfo1 = 'info'
        this.typeInfo2 = 'info'
        this.typeInfo3 = 'info'
        this.typeInfo4 = 'info'
        this.typeInfo5 = 'info'
      } else {
        let dataArray = [...val]
        this.dataArray = dataArray.pop()
        this.typeInfo4 = 'primary'
        this.typeInfo5 = 'primary'
        this.btnJiance = false
        this.btnJiagu = false
        //已上架的
        if (this.hasSeleted || this.shelves) {
          this.typeInfo1 = 'primary'
          this.typeInfo3 = 'primary'
        }
        if (this.hasSeleted || this.theShelves) {
          this.typeInfo = 'primary'
          this.typeInfo1 = 'info'
          this.typeInfo3 = 'primary'
        }
        if (this.dataArray.purgeStatus == 4||this.dataArray.purgeStatus == 5 ||this.dataArray.purgeStatus == 8||this.dataArray.purgeStatus == 2) {
          this.typeInfo5 = 'info'
          this.btnJiagu = true
        }
        if (this.dataArray.askStatus == 3 && this.dataArray.appStatus == 2) {
          this.hasSeleted = false
          this.theShelves = false
          this.shelves = true
          this.deletesBtn = true
          this.typeInfo = 'info'
          // this.typeInfo2 = "primary";
          this.typeInfo1 = 'primary'
        } else if (
          this.dataArray.askStatus == 3 &&
          this.dataArray.appStatus == 1
        ) {
          this.hasSeleted = false
          this.theShelves = true
          this.shelves = false
          this.deletesBtn = false
          this.typeInfo = 'primary'
          this.typeInfo1 = 'info'
          this.typeInfo2 = 'primary'
        } else if (
          this.dataArray.askStatus == 3 &&
          this.dataArray.appStatus == 3
        ) {
          this.hasSeleted = false
          this.theShelves = true
          this.shelves = false
          this.deletesBtn = false
          this.typeInfo2 = 'primary'
        } else {
          this.shelves = true
          this.theShelves = true
          this.hasSeleted = false
          this.deletesBtn = false
          this.typeInfo = 'info'
          this.typeInfo1 = 'info'
          this.typeInfo2 = 'primary'
        }
      }
      if (this.multipleSelection.length > 1) {
        this.typeInfo4 = 'info'
        this.btnJiance = true
        this.typeInfo5 = 'info'
        this.btnJiagu = true
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.pageNumber = page
      this.queryObj.rowNumber = limit
      // 调用查询方法
      this.tableList()
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false
    },
    //当前页全选
    curSelection() {
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    //所有页全选
    // toggleAllSelection() {
    //   this.disabled = false;
    //   this.$refs.multipleTable.toggleAllSelection();
    // },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    },
    //删除
    deletes(val) {
      this.$confirm(this.$t('AppManage.Appdetection.Selectedapp'),
        this.$t('public.PromptMessage'),
        {
          confirmButtonText: this.$t('public.Verify'),
          cancelButtonText: this.$t('public.Cancel'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.deleteBaths(val)
        })
        .catch(() => {
          return false
        })
    },
    // 关闭
    handleClose() {
      this.visibaelFlag = false //提示消息  关闭
      this.dialogVisible = false //详情弹框  关闭
      this.jiaGuVisible = false //加固详情
      this.downFlag = false
      this.installFlag = false
    },
    heightCustom() {
      this.height.height = this.num
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
:v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: none;
}
.el-linkStyle {
  display: flex;
  cursor: pointer;
  color: #1e89e5;
  img {
    width: 20px;
    line-height: 20px;
    // margin-top: 20px;
  }
}
//表格操作图片
// .action_icon {
//   margin: 0px 3px !important;
//   border: 0px !important;
//   background: rgba(255, 255, 255, 0.1);
//   img {
//     margin-bottom: -4px !important;
//   }
// }
.mt15 {
  padding: 10px 10px;
}
.formBox {
  //全选弹框
  position: relative;
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 32px;
    left: 20px;
    z-index: 999;
    // background:rgba(255, 255, 255,.1)
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}
.processManage .callenia {
  height: 0;
}
.clo24 {
  margin: 10px 0;
}
.col12 {
  text-align: center;
  margin: 60px 0;
  .el-button {
    width: 180px;
  }
}
::v-deep .el-checkbox__label {
  color: #000;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000;
}
::v-deep .el-table__empty-text {
  line-height: 0px;
  width: 60px;
  color: #909399;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0px;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
  display: none !important;
}
</style>
